.btn {
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.btn span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.section1 {
  min-height: calc((26.6666666667vw - 16px) * 0.73);
}
.univers-title {
  bottom: 1.25rem;
  inset-inline-end: 0;
  padding: 0.625rem 1rem 0.625rem 1.5rem;
  line-height: 130%;
  text-align: end;
  margin-inline-start: 1.5rem;
  border-end-start-radius: 6.25rem;
  border-start-start-radius: 6.25rem;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.slider-back {
  position: absolute;
  height: 30rem;
  width: calc(100% - 40px);
  background: #2b403b;
  margin: auto;
  margin-right: 0 !important;
  margin-left: 40px !important;
  border-start-start-radius: 12.5rem;
  border-end-start-radius: 12.5rem;
}
.col {
  flex: 0 0 75%;
  max-width: 75%;
  margin-inline-start: 25%;
}
.video-list-headtitle {
  line-height: 120%;
  letter-spacing: -0.0125rem;
}
.player-video-btn {
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border: none;
  padding: 0.9375rem 1.75rem 1.0625rem;
}
.owl-theme .owl-nav {
  height: 50px;
  text-align: right !important;
  margin-top: 50px !important;
  margin-right: 100px !important;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  color: #25485a !important;
  background: #fff !important;
  border-radius: 100%;
  text-align: center;
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  font-weight: 500;
  font-size: 2rem !important;
  line-height: 1.5rem;
  border: 4px solid #767676;
  text-decoration: none;
  padding: 0.9375rem 1.75rem 1.0625rem !important;
  white-space: nowrap;
  max-width: 100%;
  min-width: 3.5rem;
  min-height: 3.5rem;
  box-shadow: 0 0 0 0 rgba(227, 219, 209, 0.5);
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.find-btn {
  transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  border: none;
}
